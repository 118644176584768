import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "header": "Hello! I'm Güngör Murat Yılmaz, Breathing Life into Your Creativity",
      "subHeader":"Designer & Promt Engineer",
      "p1":"Chasing uncharted, creative, limitless ideas.",
      "p2":"Creating exceptional outcomes by integrating engineering and design!",
      "subHeader1":"SKILLS",
      "subHeader2":"PROJECTS",
      "subHeader3":"CONTACT",
      "email":"E-MAIL",
      "copyrights":"© Coded by Halil İbrahim Çolak aka Wynee Designed by Güngör Murat Yilmaz aka ElbisKuha"
    }
  },
  de: {
    translation: {
      "header": "Hallo! Ich bin Güngör Murat Yılmaz und hauche Ihrer Kreativität Leben ein.",
      "subHeader":"Designer & Promt Ingenieur",
      "p1":"Ich verfolge unerforschte, kreative und grenzenlose Ideen.",
      "p2":"Durch die Integration von Ingenieurwesen und Design schaffe ich außergewöhnliche Ergebnisse!",
      "subHeader1":"FÄHIGKEITEN",
      "subHeader2":"PROJEKTE",
      "subHeader3":"KONTAKT",
      "email":"E-MAIL",
      "copyrights":"© Programmiert von Halil İbrahim Çolak aka Wynee, Gestaltet von Güngör Murat Yilmaz aka ElbisKuha"
    }
  },
  tr: {
    translation: {
        "header": "Merhaba! Ben Güngör Murat Yılmaz, Yaratıcılığınıza Hayat Veriyorum",
        "subHeader":"Designer & Promt Mühendisi",
        "p1":"Keşfedilmemiş, yaratıcı ve sınırsız fikirlerin peşindeyim.",
        "p2":"Mühendislik ve tasarımı birleştirerek olağanüstü sonuçlar yaratıyorum!",
        "subHeader1":"YETENEKLER",
        "subHeader2":"PROJELER",
        "subHeader3":"İLETİŞİM",
        "email":"E-POSTA",
        "copyrights":"© Halil İbrahim Çolak tarafından kodlandı, Güngör Murat Yılmaz tarafından tasarlandı."
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", 
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;