import './App.css';
import { useTranslation } from 'react-i18next';


function App() {

  const { t, i18n } = useTranslation();

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
  } 

  return (
    [
      
      <div className='absolute flex flex-row gap-2 right-32 top-[23px] justify-center'>
        <img alt="trflag" src="/tr.png"  className={`w-8 hover:cursor-pointer hover:scale-105 active:scale-95 ${i18n.language === "tr" ? "outline outline-2 outline-offset-1" : ""}`} onClick={() => changeLang("tr")} />
        <img alt="usflag" src="/us.webp" className={`w-8 hover:cursor-pointer hover:scale-105 active:scale-95 ${i18n.language === "en" ? "outline outline-2 outline-offset-1" : ""}`} onClick={() => changeLang("en")}/>
        <img alt="usflag" src="/de.png" className={`w-8 hover:cursor-pointer hover:scale-105 active:scale-95 ${i18n.language === "de" ? "outline outline-2 outline-offset-1" : ""}`} onClick={() => changeLang("de")}/>
      </div>,
      <span className='absolute right-8 top-4 text-black md:text-white font-bold text-lg'>ElbisKuha</span>,
      <div className='w-full h-fit md:h-screen flex flex-col md:flex-row'>
<div className='bg-[url("https://www.gungormuratyilmaz.com/assets/px.gif")] bg-cover
         rightSide p-24 w-full md:w-2/4 h-[500px] md:h-full items-center justify-center flex'>
          <img id="pxArt_Sac" alt="pixelart" className='w-full h-full object-contain' src="https://cdn.discordapp.com/attachments/1128816551601123448/1128827000862294126/pxArt_Sac.png" alt="pixelart" />
        </div>
        <div className='flex flex-col justify-center px-6 md:px-14 leftSide w-full md:w-2/4 h-[800px] md:h-full'>
          <h1 className='text-md lg:text-xl xl:text-3xl font-black text-white font-press-start'>{t('header')}</h1>
          <h2 className='text-sm lg:text-lg xl:text-2xl font-black text-fuchsia-950 font-press-start'>{t('subHeader')}</h2>
          <p className='text-xs lg:text-sm xl:text-md text-white mt-3'>
          {t('p1')}
          </p>
          <p className='text-xs lg:text-sm xl:text-md mt-1 text-fuchsia-200'> 
          {t('p2')}
          </p>
          <h3 className='mt-16 text-white font-press-start text-white'>{t('subHeader1')}</h3>
          {
            //<img className='w-96 mt-4' src="https://media.discordapp.net/attachments/906984212631015484/1101192461151375380/logos2.png"></img>
            <p className='font-press-start text-[9px] mt-4 mb-4 text-white'>
              Stable Diffusion, Stable Diffusion XL, Retrieval-based Voice Conversion, Photoshop, Premiere Pro, After Effects, XD, InDesign
            </p>
          }
          <div className='flex mt-4 text-blue-400 flex-col'>
            <h3 className='text-white font-press-start'>{t('subHeader2')}</h3>
            <div className='flex gap-4 xl:gap-3 items-center flex-wrap mb-4'>
              <a href='https://www.deviantart.com/gmy-elbiskuha' target='_blank' rel="noreferrer"  className='hover:scale-105 active:scale-95 transition-all'>
                <img className='w-16 h-16 xl:w-24 xl:h-24 object-contain' alt="AI Instagram" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/DeviantArt_Logo.svg/512px-DeviantArt_Logo.svg.png" />
              </a>
              <a href='https://www.behance.net/gmuratyilmaz' target='_blank' rel="noreferrer"  className='hover:scale-105 active:scale-95 transition-all'>
                <img className='w-16 h-16 xl:w-24 xl:h-24 object-contain' alt="ElbisKuha Behance" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Behance_logo.svg/512px-Behance_logo.svg.png" />
              </a>
              <a href='https://www.youtube.com/channel/UCkVsmrQXkenHF1fyONpvUfg' target='_blank' rel="noreferrer"  className='hover:scale-105 active:scale-95 transition-all'>
                <img className='w-16 h-16 xl:w-24 xl:h-24 object-contain' alt="ElbisTV" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/YouTube_Logo_2017.svg/512px-YouTube_Logo_2017.svg.png" />
              </a>
              <a href="https://soundcloud.com/guengoer-murat-yilmaz" target="_blank" rel="noreferrer"   className='hover:scale-105 active:scale-95 transition-all'>
                <img className='w-10 h-10 xl:w-14 xl:h-14 object-contain' alt="RVC Sounds" src="https://upload.wikimedia.org/wikipedia/de/thumb/f/f4/SoundCloud_-_Logo.svg/800px-SoundCloud_-_Logo.svg.png"/>
              </a>
              <a href="https://www.instagram.com/gmuratyilmaz" target='_blank' rel="noreferrer"  className='flex items-center gap-2 text-xl text-blue-300 font-press-start hover:scale-105 active:scale-95 transition-all'>
                <img src="https://cdn.discordapp.com/attachments/1128816551601123448/1129167454904516688/pxArt_4.png" alt="Personal Instagram" className='w-10 h-10 xl:w-14 xl:h-14 rounded-xl'/>
                +
              </a>
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <h3 className='text-white font-press-start'>{t('subHeader3')}</h3>
            <span className='mt-4 font-press-start text-white text-[9px]'>{t('email')} : info@gungormuratyilmaz.com</span>
            <span className='font-press-start text-white text-[9px]'>Discord : ElbisKuha</span>
            <span className='font-press-start text-white text-[8px] mb-4 mt-8'>{new Date().getFullYear()}{t('copyrights')}</span>
          </div>

        </div>
      </div>
    ]

  );
}

export default App;
